import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MainTopNav from './MainTopNav';
import ThumbnailHomeHeroAnimation from './ThumbnailHomeHeroAnimation';
import './ThumbnailHome.css';  // Assuming you have a CSS file for styling
// import ThumbnailCreationWithCursor from './ThumbnailCreationWithCursor'; // Import the animation component

const ThumbnailHome = () => {
  const navigate = useNavigate();
  const userId = useSelector((state) => state.user.userId);

  const handleGo = () => {
    if (userId === null || userId === 'null' || userId === undefined) {
      navigate('/register');
    } else {
      navigate('/thumbnails/create');
    }
  };

  return (
  <div className="universal-container">
    <div className="universal-content-container">
      <div className="splash-page-container">
        <div className="splash-page">
          <MainTopNav />
          <header className="hero-section">
            <div className="hero-content">
              <h1>More Views. <br/>Less Effort.</h1>
              <p>Giggy <span style={{ fontStyle: 'italic' }}>automagically</span> creates customized YouTube thumbnails that click.</p>
              <button className="cta-button" onClick={handleGo}>Create Thumbnails <span>- it's free</span></button>
            </div>
            <div className="hero-illustration">
              <img src="thumbnail-home-hero.jpg" alt="Hero Illustration" />
            </div>
          </header>
          <div className="animation-wrapper">
            <section className="hero-animation">
              <ThumbnailHomeHeroAnimation />
            </section>
            <section className="how-it-works">
              <h2>Get Videos On YouTube Faster</h2>
              <p><strong>Create thumbnails in seconds.</strong> Simply tell us your video title and upload a photo, and we'll generate multiple designs for you to choose from. You can also draw inspiration from an existing thumbnail. Fine-tune your favorite with our intuitive editor, adding custom shapes, text, and styles to make it uniquely yours.</p>
            </section>
          </div>
          <div className="animation-wrapper">
            <section className="experiment-section">
              <div className="experiment-text">
                <h2>Experiment And Grow</h2>
                <p>For only pennies per custom thumbnail<strong></strong>, we enable you to try different designs effortlessly with A/B testing and see what drives your audience to watch your video. Plus, for a limited time, enjoy a free trial when you sign up — no credit card required!</p>
                <button className="cta-button experiment-button" onClick={handleGo}>Create Thumbnails <span>- it's free</span></button>
              </div>
              <div className="experiment-image">
                <img src="logo-dancing.gif" alt="Hero Illustration" />
              </div>
            </section>
          </div>
          <div className="spacer"/>
          <div className="animation-wrapper">
              <div className="data-illustration">
                <img src="thumbnail-home-data.jpg" alt="Hero Illustration" />
              </div>
              <div className="data-text">
                <h2>Use Our Data To Your Advantage</h2>
                <p>After analyzing tens of thousands of thumbnails and gathering insights from top creators, we’ve developed best practices for designing high-conversion thumbnails. We’ve bottled this into a <strong>magic algorithm</strong> to create thumbnails tailored just for you.</p>
              </div>
          </div>
          <div className="spacer"/>
          <div className="bottom-call-to-action">
            <h2>Try Giggy for Free Today</h2>
            <p>Get giggy with it. Start creating your custom YouTube thumbnails now, no credit card required.</p>
            <button className="cta-button pricing-button" onClick={handleGo}>Create Thumbnails <span>- it's free</span></button>
          </div>
        </div>
          <footer className="footer-section">
            <p>&copy; 2024 GRQ Digital Capital LLC. All rights reserved.</p>
          </footer>
      </div>
    </div>
  </div>
  );
};

export default ThumbnailHome;
