import React from 'react';

const Privacy = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Privacy Policy</h1>
      <p style={styles.text}>
        At Giggy, part of GRQ Digital Capital LLC ("we," "our," or "us"), we are committed to protecting your privacy and ensuring transparency regarding the collection, use, and security of your personal information. This Privacy Policy explains how we collect, use, share, store, and safeguard your personal information in connection with our services.
      </p>

      <h2 style={styles.subTitle}>1. Information We Collect</h2>
      <p style={styles.text}>
        We collect personal information to provide and improve our services. The types of information we collect include:
      </p>
      <ul style={styles.list}>
        <li><b>Information You Provide:</b> Contact details, account information, user content, payment information (processed by third-party providers).</li>
        <li><b>Automatically Collected Data:</b> Usage patterns, device information, cookies, and analytics.</li>
        <li><b>Third-Party Data:</b> Information from your employer, partners, or public sources.</li>
      </ul>

      <h2 style={styles.subTitle}>2. How We Use Your Information</h2>
      <p style={styles.text}>
        We use the information we collect for the following purposes:
      </p>
      <ul style={styles.list}>
        <li>To provide, maintain, and improve our services.</li>
        <li>To communicate with you and provide customer support.</li>
        <li>To personalize your experience and offer targeted advertising.</li>
        <li>To comply with legal obligations, prevent fraud, and ensure security.</li>
        <li>For research and product improvement.</li>
      </ul>

      <h2 style={styles.subTitle}>3. Cookies and Tracking Technologies</h2>
      <p style={styles.text}>
        We use cookies for essential operations, personalization, analytics, and targeted advertising. You may control cookies via your browser settings.
      </p>

      <h2 style={styles.subTitle}>4. How We Share Your Information</h2>
      <p style={styles.text}>
        We do not sell your personal information. However, we may share your data in the following circumstances:
      </p>
      <ul style={styles.list}>
        <li>With service providers, for hosting, analytics, and payment processing.</li>
        <li>With legal authorities if required by law.</li>
        <li>As part of corporate transactions such as mergers or acquisitions.</li>
        <li>With your consent, to share your data with other users or partners.</li>
      </ul>

      <h2 style={styles.subTitle}>5. Data Security and Retention</h2>
      <p style={styles.text}>
        We implement security measures to protect your data. Information is retained as long as necessary for business, legal, or operational purposes. Voice data (if applicable) is retained for up to 3 years after your last interaction.
      </p>

      <h2 style={styles.subTitle}>6. User-Uploaded Images</h2>
      <p style={styles.text}>
        Any images you upload to Giggy's platform will not be used for training AI models or resold for any purpose. We respect your ownership of content, and images are stored solely for the purpose of providing services to you.
      </p>

      <h2 style={styles.subTitle}>7. Your Rights</h2>
      <p style={styles.text}>
        You have the right to access, update, or delete your data. You may opt-out of marketing communications or personalized ads. Additional rights may apply depending on your location, such as California or the EU.
      </p>

      <h2 style={styles.subTitle}>8. International Data Transfers</h2>
      <p style={styles.text}>
        Your data may be transferred to and processed on servers in the U.S. or other jurisdictions where we operate, with appropriate safeguards to protect your information.
      </p>

      <h2 style={styles.subTitle}>9. Payment Processing</h2>
      <p style={styles.text}>
        Payments are processed by third-party providers. Please review their privacy policies for more information.
      </p>

      <h2 style={styles.subTitle}>10. Children's Privacy</h2>
      <p style={styles.text}>
        Our services are not intended for individuals under the age of 18. We do not knowingly collect data from children.
      </p>

      <h2 style={styles.subTitle}>11. Changes to This Privacy Policy</h2>
      <p style={styles.text}>
        We may update this policy periodically. Significant changes will be communicated to users in advance.
      </p>

      <h2 style={styles.subTitle}>12. DMCA Compliance</h2>
      <p style={styles.text}>
        Giggy follows the DMCA guidelines for handling copyright claims. If your intellectual property rights are violated, you can contact us at hello@giggy.ai to file a complaint. We will respond by removing infringing content and may suspend or terminate the accounts of repeat offenders.
      </p>

      <h2 style={styles.subTitle}>13. Contact Us</h2>
      <p style={styles.text}>
        If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:support@giggy.ai">support@giggy.ai</a>.
      </p>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
    fontFamily: 'Arial, sans-serif'
  },
  title: {
    fontSize: '2em',
    marginBottom: '20px'
  },
  subTitle: {
    fontSize: '1.2em',
    marginTop: '20px',
    marginBottom: '20px',
  },
  text: {
    fontSize: '1em',
    lineHeight: '1.6'
  },
  list: {
    marginTop: '10px',
    marginBottom: '10px',
    paddingLeft: '20px',
    listStyleType: 'disc'
  }
};

export default Privacy;
